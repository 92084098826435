<template>
	<!-- begin:: Header Topbar -->
	<div class="topbar">
		<router-link :to="{ name: 'checkout' }">
			<span class="fa-stack fa-2x has-badge mr-5" :data-count="cartCount()">
				<i class="fa fa-circle fa-stack-2x"></i>
				<i class="fa fa-shopping-cart fa-stack-1x fa-inverse"></i>
			</span>
		</router-link>
		<!--begin: User Bar -->
		<KTQuickUser></KTQuickUser>
		<!--end: User Bar -->
	</div>
	<!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
	.dropdown-toggle {
		padding: 0;
		&:hover {
			text-decoration: none;
		}

		&.dropdown-toggle-no-caret {
			&:after {
				content: none;
			}
		}
	}

	.dropdown-menu {
		margin: 0;
		padding: 0;
		outline: none;
		.b-dropdown-text {
			padding: 0;
		}
	}
	.fa-2x {
		font-size: 24px !important;
	}
	.fa-stack {
		display: inline-block;
		height: 50px;
		line-height: 48px;
		position: relative;
		vertical-align: middle;
		width: 50px;
	}
	.fa-stack[data-count]:after {
		position: absolute;
		right: 0%;
		top: 0%;
		content: attr(data-count);
		font-size: 13px;
		padding: 4px;
		border-radius: 50px;
		line-height: 12px;
		color: #1bc5bd;
		text-align: center;
		min-width: 22px;
		min-height: 20px;
		background: white;
		border-style: solid;
		border-width: 1px;
		font-weight: 500;
	}
	.fa-circle {
		color: #c9f7f5;
	}
	.fa-inverse {
		color: #1bc5bd;
	}
}
</style>

<script>
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
import { mapGetters } from "vuex";

export default {
	name: "KTTopbar",
	components: {
		KTQuickUser,
	},
	computed: {
		...mapGetters(["cartCount"]),
	},
};
</script>
